import scrollMonitor from "scrollmonitor";
import gsap from "gsap";
import { disablePageScroll, enablePageScroll } from "scroll-lock";
import CookieConsent from "./cookie-consent";

class ISI {
  constructor(isiElm, triggerEl, seeMoreElm, linkTo, fixedElm) {
    this.isiElm = isiElm;
    this.fixedElm = fixedElm;
    this.triggerEl = triggerEl;
    this.seeMoreElm = seeMoreElm;
    this.linkTo = linkTo;
    this.isiLocked = false;
  }

  init() {
    this.createWatcher();
    this.addListeners();
  }

  addListeners() {
    this.seeMoreElm.addEventListener("click", () => {
      if (!this.isiLocked) {
        gsap.to(this.fixedElm, { top: 0, duration: 0.5 });
        gsap.to(this.seeMoreElm, { rotationZ: "180deg" });
        disablePageScroll(this.fixedElm);
        this.isiLocked = true;
      } else {
        gsap.to(this.fixedElm, { top: "85%", duration: 0.5 });
        gsap.to(this.seeMoreElm, { rotationZ: "-180deg" });
        enablePageScroll(this.fixedElm);
        this.isiLocked = false;
      }
    });
  }

  createWatcher() {
    this.elementWatcher = scrollMonitor.create(this.isiElm);
    this.elementWatcher.enterViewport(() => {
      this.fixedElm.classList.add("hidden");
    });
    this.elementWatcher.exitViewport(() => {
      if (this.elementWatcher.isBelowViewport) {
        this.fixedElm.classList.remove("hidden");
      }
    });
  }
}

const init = () => {
  const cc = new CookieConsent();
  const isiEl = document.querySelector("#isi");
  const isiFixed = document.querySelector("#isi-fixed");
  const linkTo = document.querySelector("#link-to-isi");
  const isiTrigger = document.querySelector(".isi-trigger");
  const seeMore = document.querySelector(".see-more");
  const modalTrigger = document.querySelector(".eligible-trigger");
  const modal = document.querySelector("#eligible-modal");
  const closeModal = document.querySelector(".close-modal");

  if (modalTrigger) {
    modalTrigger.addEventListener("click", () => {
      gsap.to(modal, { autoAlpha: 1, duration: 0.5 });
      disablePageScroll(modal);
    });
  }

  closeModal.addEventListener("click", () => {
    gsap.to(modal, { autoAlpha: 0, duration: 0.25 });
    enablePageScroll(modal);
  });

  const isi = new ISI(isiEl, isiTrigger, seeMore, linkTo, isiFixed);
  isi.init();
};

document.addEventListener("DOMContentLoaded", init);
