export default class CookieConsent {
  constructor() {
    this.acceptButtonId = "acceptButton";
    this.rejectButtonId = "rejectButton";
    this.gtmID = "GTM-5LFP3FZ";

    this.initialize();
  }

  initialize() {
    const consentGiven = localStorage.getItem("consentGiven");
    console.log("ini", consentGiven);

    if (consentGiven !== null) {
      window.consentGiven = consentGiven;
      if (consentGiven) {
        this.loadGoogleAnalytics();
      }
    } else {
      this.addEventListeners();
    }
  }

  loadGoogleAnalytics() {
    (function (w, d, s, l, i) {
      w[l] = w[l] || [];
      w[l].push({
        "gtm.start": new Date().getTime(),
        event: "gtm.js",
      });
      var f = d.getElementsByTagName(s)[0],
        j = d.createElement(s),
        dl = l != "dataLayer" ? "&l=" + l : "";
      j.async = true;
      j.src = "https://www.googletagmanager.com/gtm.js?id=" + i + dl;
      f.parentNode.insertBefore(j, f);
    })(window, document, "script", "dataLayer", this.gtmID);

    document.body.insertAdjacentHTML(
      "afterbegin",
      `<noscript><iframe src="https://www.googletagmanager.com/ns.html?id=${this.gtmID}" height="0" width="0" style="display:none;visibility:hidden"></iframe></noscript>`
    );
  }

  createCookieConsent() {
    const styleElement = document.createElement("style");
    styleElement.innerHTML = `
          .cookie-consent {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            border-top: 3px solid rgb(3, 55, 22); 
            background-color: white;
            padding: 30px 20px;
            position: fixed;
            bottom: 0;
            left: 0;
            width: 100%;
            z-index: 1000;
          }

          .cookie-consent-inner {
            max-width: 768px;
            margin: auto;
          }

          .cookie-consent p {
            margin: 0;
          }

          .cookie-consent .consent-buttons {
            margin-right: 5px;
            margin-top: 1rem;
            display: flex;
            justify-content: center;
          }

          .cookie-consent button:nth-of-type(2) {
            margin-left: 5px;
          }

          @media(max-width: 768px)  {
            .cookie-consent .consent-buttons {
              flex-direction: column;
            }

            .cookie-consent button:nth-of-type(2) {
              margin-top: 5px;
              margin-left: 0px;
            }
          }

          .consent-buttons .btn:hover {
            text-decoration: none !important;
            color: #ffffff;
          }
        `;

    const cookieConsentHtml = `
          <div class="cookie-consent">
            <div class="cookie-consent-inner">
              <p class="pt-0 text-center text-md">
                    We use cookies to capture how users interact with our website content so we can continually improve user
                    experiences.
                    Clicking “accept” allows us to use these cookies when you visit our sites. For more information, read our
                    <a class="underline transition duration-200 hover:opacity-75 text-blue" href="https://covispharma.com/index.php/privacy-policy/" target="_blank">Privacy Policy</a>.
              </p>
              <div class="consent-buttons">
                <button class="bg-orange cursor-pointer hover:text-green-dark transition duration-75 text-white font-bold text-md text-center relative px-8 py-2" id="${this.acceptButtonId}">Accept</button>
                <button class="bg-orange cursor-pointer hover:text-green-dark transition duration-75 text-white font-bold text-md text-center relative px-8 py-2" id="${this.rejectButtonId}">Reject</button>
              </div>
            </div>
          </div>
        `;

    document.head.appendChild(styleElement);
    document.body.insertAdjacentHTML("beforeend", cookieConsentHtml);
  }

  addEventListeners() {
    this.createCookieConsent();
    const acceptButton = document.getElementById(this.acceptButtonId);
    const rejectButton = document.getElementById(this.rejectButtonId);

    acceptButton.addEventListener("click", () => this.setCookieConsent(true));
    rejectButton.addEventListener("click", () => this.setCookieConsent(false));
  }

  setCookieConsent(consent) {
    localStorage.setItem("consentGiven", consent);
    this.hideCookieConsent();
    if (consent) {
      this.loadGoogleAnalytics();
    }
  }

  hideCookieConsent() {
    const cookieConsentElement = document.querySelector(".cookie-consent");
    if (cookieConsentElement) {
      cookieConsentElement.style.display = "none";
    }
  }
}
